import React, { useCallback, useState } from 'react'
import { Box, Theme, Typography } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import clsx from 'clsx'
import { useHistory } from 'react-router-dom'
import { useDispatch } from 'react-redux'

import { RoutePath } from '../../routes'
import FooterControls from '../../components/FooterControls'
import ThemedButton from '../../components/common/ThemedButton'
import appointmentActions from '../../store/appointment/actions'
import { IndicatedPaymentMethod } from '../../store/appointment/types'

const useStyles = makeStyles(
  (theme: Theme) => ({
    root: {
      padding: theme.spacing(5, 0, 4),
    },
    text: {
      fontSize: '1.25rem',
      fontFamily: 'NeurialGrotesk',
      color: theme.palette.primary.dark,
      fontWeight: 700,
      lineHeight: '130%',
      marginBottom: theme.spacing(2),
    },
    content: {
      flex: 1,
      overflowY: 'auto',
      padding: theme.spacing(0, 4, 1.5),
    },
    noteWrapper: {
      padding: theme.spacing(2, 2.5),
      background: 'rgba(25, 99, 198, 0.1)',
      borderRadius: '20px 20px 20px 0px',
    },
    note: {
      lineHeight: '20px',
      fontSize: '0.875rem',
      fontWeight: 500,
      color: theme.palette.primary.dark,
    },
    bold: {
      fontWeight: 700,
    },
    reasonButton: {
      background: theme.palette.background.default,
      border: `2px solid ${theme.palette.primary.light}`,
      borderRadius: '20px',
      height: 50,
      width: '100%',
      marginTop: theme.spacing(1.5),
      '&:hover': {
        backgroundColor: theme.palette.primary.light,
        border: `2px solid ${theme.palette.primary.main}`,
        cursor: 'pointer',
      },
    },
    selectedReasonButton: {
      backgroundColor: theme.palette.primary.light,
      border: `2px solid ${theme.palette.primary.main}`,
    },
    reasonButtonText: {
      fontFamily: 'NeurialGrotesk',
      fontSize: '0.875rem',
      lineHeight: 1.3,
      textAlign: 'center',
      fontWeight: 500,
      color: theme.palette.primary.main,
    },
  }),
  { name: 'InsuranceAutoVerificationFailed' }
)

// TODO: use existing Enum?
enum NextOptions {
  VerifyManually = 'Verify Manually',
  SelfPay = 'Self Pay',
}

const InsuranceAutoVerificationFailed: React.FC = () => {
  const classes = useStyles()
  const history = useHistory()
  const dispatch = useDispatch()

  const [nextOptions, setNextOption] = useState<NextOptions | null>(null)

  const handleGoNext = useCallback(() => {
    if (nextOptions === NextOptions.SelfPay) {
      dispatch(
        appointmentActions.setSelectedPaymentMethod(IndicatedPaymentMethod.cash)
      )
      history.replace(RoutePath.createAppointment)
    } else {
      dispatch(
        appointmentActions.setSelectedPaymentMethod(
          IndicatedPaymentMethod.insurance
        )
      )
      history.push(RoutePath.insuranceManualVerification)
    }
  }, [dispatch, history, nextOptions])

  const handleGoBack = useCallback(() => {
    history.push(RoutePath.insuranceInfo)
  }, [history])

  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="space-between"
      className={clsx(classes.root)}
    >
      <Box
        display="flex"
        justifyContent="space-between"
        flexDirection="column"
        className={classes.content}
      >
        <Typography className={classes.text}>
          If your insurance cannot be verified automatically, our support team
          will verify it manually.
        </Typography>
        <Box>
          <Box className={classes.noteWrapper}>
            <Typography className={classes.note}>
              <span className={classes.bold}>Please Note:</span> Manual
              verification can take up to 30 minutes.
            </Typography>
          </Box>

          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            className={clsx(classes.reasonButton, {
              [classes.selectedReasonButton]:
                nextOptions === NextOptions.VerifyManually,
            })}
            onClick={() => setNextOption(NextOptions.VerifyManually)}
          >
            <Typography className={classes.reasonButtonText}>
              {NextOptions.VerifyManually}
            </Typography>
          </Box>
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            className={clsx(classes.reasonButton, {
              [classes.selectedReasonButton]:
                nextOptions === NextOptions.SelfPay,
            })}
            onClick={() => setNextOption(NextOptions.SelfPay)}
          >
            <Typography className={classes.reasonButtonText}>
              {NextOptions.SelfPay}
            </Typography>
          </Box>
        </Box>
      </Box>

      <FooterControls paddingX={4}>
        <ThemedButton disabled={!nextOptions} onClick={handleGoNext}>
          Continue
        </ThemedButton>
        <ThemedButton onClick={handleGoBack} variant="text">
          Back
        </ThemedButton>
      </FooterControls>
    </Box>
  )
}

export default InsuranceAutoVerificationFailed
