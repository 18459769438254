import React, { useCallback, useEffect, useState } from 'react'
import {
  CircularProgress,
  Grid,
  InputAdornment,
  Theme,
  Typography,
} from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import clsx from 'clsx'
import { isNaN } from 'lodash'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'

import appointmentActions from 'src/store/appointment/actions'
import MaskedInput from 'react-text-mask'
import GoogleMap from '../../components/map/GoogleMap'
import {
  getAppointmentClinic,
  getClinicByZipLoading,
  getEligibleFlag,
} from '../../store/appointment/selectors'
import FooterButtons from '../../components/FooterButtons'
import ThemedTextField from '../../components/common/ThemedTextField'
import { RoutePath } from '../../routes'
import useDebounce from '../../hooks/useDebounce'
import useGoogleMapApi from '../../hooks/useGoogleMapApi'
import {
  isFailure,
  isLoading,
  isSuccess,
  LoadingContext,
} from '../../utils/types'
import { US_ZIP_MASK } from '../../utils/general'
import { useLoadingChange } from '../../hooks/useLoadingChange'
import theme from '../../utils/theme'
import { useClinicPreview } from '../../hooks/useClinicPreview'
import PhoneNumberButton from '../../components/common/PhoneNumberButton'
import { config } from '../../utils/config'

const useStyles = makeStyles(
  (theme: Theme) => ({
    root: {
      padding: theme.spacing(6, 0, 4),
      height: '100%',
    },
    headerContainer: {
      padding: theme.spacing(0, 4),
    },
    text: {
      fontFamily: 'Arial',
      color: theme.palette.primary.dark,
      lineHeight: 1.4,
    },
    title: {
      fontWeight: 'bold',
      fontSize: '2.25rem',
      letterSpacing: '-1px',
    },
    phoneButtonContainer: {
      padding: theme.spacing(3, 4),
    },
    buttonRoot: {
      padding: theme.spacing(2),
      border: `2px solid ${theme.palette.primary.light}`,
      boxSizing: 'border-box',
      borderRadius: 12,
      '&:hover': {
        border: `2px solid ${theme.palette.primary.main}`,
        backgroundColor: 'rgba(25, 99, 198, 0.04)',
      },
    },
    mapContainer: {
      height: 235,
      position: 'relative',
    },
    zipCodeInput: {
      width: '100%',
    },
    zipCodeWrapper: {
      padding: theme.spacing(2, 4, 0),
    },
    loadingAdornment: {
      marginRight: theme.spacing(1),
    },
    errorText: {
      color: theme.palette.error.main,
      fontSize: '0.875rem',
      marginTop: theme.spacing(0.5),
    },
    actions: {
      padding: theme.spacing(0, 4),
    },
  }),
  { name: 'AtHomeCare' }
)

interface AtHomeCareProps {
  selectedClinicId: number
  allClinicsPageUrl: string
  widgetType: string
}

const AtHomeCare: React.FC<AtHomeCareProps> = ({
  selectedClinicId,
  allClinicsPageUrl,
  widgetType,
}) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const history = useHistory()

  const { clinicInfo, clinicInfoIsLoading } = useClinicPreview(
    selectedClinicId,
    widgetType,
    true
  )

  const eligibleFlag = useSelector(getEligibleFlag)
  const clinicByZipLoading = useSelector(getClinicByZipLoading)
  const clinicByZipIsLoading = isLoading(clinicByZipLoading.state)

  const [apiError, setApiError] = useState('')

  const handleLoadingChange = useCallback(
    (newLoading: LoadingContext) => {
      if (isSuccess(newLoading.state)) {
        history.push(
          eligibleFlag
            ? {
                pathname: RoutePath.appointmentTime,
                state: { initialPage: RoutePath.atHomeCare },
              }
            : RoutePath.atHomeCareFailure
        )
      }
      if (isFailure(newLoading.state)) {
        setApiError(newLoading.message as string)
      }
    },
    [history, eligibleFlag]
  )

  useLoadingChange(handleLoadingChange, clinicByZipLoading)

  const [zipCode, setZipCode] = useState('')
  const { updateGoogleMapObjects } = useGoogleMapApi()

  const debouncedZipCodeValue = useDebounce(zipCode, 300)

  useEffect(() => {
    if (debouncedZipCodeValue && !isNaN(Number(debouncedZipCodeValue))) {
      setApiError('')
      dispatch(
        appointmentActions.getClinicByZip.request({
          zipCode: Number(debouncedZipCodeValue),
        })
      )
    }
  }, [debouncedZipCodeValue])

  const selectedClinicInfo = useSelector(getAppointmentClinic)

  const handleOpenHomePage = useCallback(() => {
    window.open(`${config.server.aucWpHost}/in-clinic-care`)
  }, [allClinicsPageUrl])

  const handleOpenAvailableAppointments = useCallback(() => {
    history.push({
      pathname: RoutePath.appointmentTime,
      state: { initialPage: RoutePath.atHomeCare },
    })
  }, [history])

  const addCoverageArea = useCallback(
    (googleMapObj: any, googleMapsObj: any) => {
      new googleMapsObj.Circle({
        strokeColor: theme.palette.primary.main,
        strokeOpacity: 0.5,
        strokeWeight: 2,
        fillColor: theme.palette.primary.main,
        fillOpacity: 0.1,
        map: googleMapObj,
        center: { lat: 37.3253903, lng: -121.9052068 },
        radius: 32187,
      })
    },
    []
  )

  return (
    <Grid
      container
      direction="column"
      justifyContent="space-between"
      className={classes.root}
    >
      <Grid item>
        <Grid className={classes.headerContainer}>
          <Typography className={clsx(classes.title, classes.text)}>
            Book Appointment
          </Typography>
          <Typography className={classes.text}>
            Check availability in your zip code or call
          </Typography>
        </Grid>

        <Grid item className={classes.zipCodeWrapper}>
          <MaskedInput
            mask={US_ZIP_MASK}
            value={zipCode}
            onChange={(event) => {
              setZipCode(event.target.value)
            }}
            disabled={clinicByZipIsLoading}
            render={(ref: React.Ref<any>, props: any) => (
              <ThemedTextField
                ref={ref}
                classes={{ input: classes.zipCodeInput }}
                endAdornment={
                  clinicByZipIsLoading && (
                    <InputAdornment
                      position="end"
                      className={classes.loadingAdornment}
                    >
                      <CircularProgress size={30} />
                    </InputAdornment>
                  )
                }
                variant="outlined"
                color="primary"
                placeholder="Enter your zip code to check availability"
                {...props}
              />
            )}
          />
          <Typography align="center" className={classes.errorText}>
            {apiError}
          </Typography>
        </Grid>
        <Grid className={classes.phoneButtonContainer}>
          <PhoneNumberButton
            isLoading={clinicInfoIsLoading}
            phoneNumber={clinicInfo?.phoneNumber}
          />
        </Grid>
        <Grid className={classes.mapContainer}>
          <GoogleMap
            yesIWantToUseGoogleMapApiInternals
            center={{
              lat: 37.3253903,
              lng: -121.9052068,
            }}
            zoom={9}
            onGoogleApiLoaded={({ map, maps }) => {
              updateGoogleMapObjects(map, maps)
              addCoverageArea(map, maps)
            }}
          />
        </Grid>
      </Grid>
      <Grid item>
        <FooterButtons
          classes={{ root: classes.actions }}
          nextButtonLabel="See Available Appointments"
          backButtonLabel="See All Clinics"
          onNextButtonClick={handleOpenAvailableAppointments}
          onBackButtonClick={handleOpenHomePage}
          disableNext={!selectedClinicInfo?.id}
          disableBack={clinicByZipIsLoading}
        />
      </Grid>
    </Grid>
  )
}

export default AtHomeCare
