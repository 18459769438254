import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { AxiosError } from 'axios'

import { AsyncTaskStatuses, makeLoading } from 'src/utils/store'
import {
  LoadingContext,
  LoadingContextDefault,
  LoadingState,
} from '../../utils/types'
import { LabOrderDraft, LabOrderDraftCandidate, LabProduct } from './models'
import { serverAxios } from '../../utils/api/axios'
import { AddressFormValues } from '../../components/forms/AddressForm'
import { extractErrorMessage } from 'src/utils/errors'

export const getLabOrderDataAction = createAsyncThunk<
  { product: LabProduct },
  { orderId: LabProduct['id'] }
>(
  'testOrdering/getLabOrderDataAction',
  async ({ orderId }, { rejectWithValue }) => {
    try {
      return await serverAxios.get(`ecommerce/public/api/v1/product/${orderId}`)
    } catch (error) {
      return rejectWithValue(error)
    }
  }
)

export const createLabOrderDraftAction = createAsyncThunk<
  LabOrderDraft,
  LabOrderDraftCandidate
>(
  'testOrdering/createLabOrderDraftAction',
  async (candidate, { rejectWithValue }) => {
    try {
      const response: LabOrderDraft = await serverAxios.post(
        '/ecommerce/webview/api/v1/orders',
        {
          ...candidate,
        }
      )

      return response
    } catch (error) {
      return rejectWithValue(error)
    }
  }
)

export const updateLabOrderDraftAddressAction = createAsyncThunk<
  void,
  { address: AddressFormValues; draftId: LabOrderDraft['id'] }
>(
  'testOrdering/updateLabOrderDraftAddressAction',
  async ({ address, draftId }, { rejectWithValue }) => {
    try {
      await serverAxios.put(
        `/ecommerce/webview/api/v1/orders/${draftId}/address`,
        {
          ...address,
          street: address.address,
        }
      )

      return
    } catch (error) {
      return rejectWithValue(error)
    }
  }
)

export const updateLabOrderDraftBillingTypeAction = createAsyncThunk<
  void,
  { billingType: string; draftId: LabOrderDraft['id'] }
>(
  'testOrdering/updateLabOrderDraftBillingTypeAction',
  async ({ billingType, draftId }, { rejectWithValue }) => {
    try {
      await serverAxios.put(`/ecommerce/webview/api/v1/orders/${draftId}`, {
        billingType,
      })

      return
    } catch (error) {
      return rejectWithValue(error)
    }
  }
)

export const confirmLabOrderDraftAction = createAsyncThunk<
  { labOrderId: number },
  LabOrderDraft['id']
>(
  'testOrdering/confirmLabOrderDraftAction',
  async (draftId, { rejectWithValue }) => {
    try {
      const response: { labOrderId: number } = await serverAxios.post(
        `/ecommerce/webview/api/v1/orders/${draftId}/confirm`
      )

      return response
    } catch (error) {
      return rejectWithValue(error)
    }
  }
)

export const checkOrderPaymentStatus = createAsyncThunk<
  { status: AsyncTaskStatuses },
  { asyncTaskId: number }
>(
  'testOrdering/checkOrderPaymentStatus',
  async ({ asyncTaskId }, { rejectWithValue, dispatch }) => {
    try {
      const response: any = await serverAxios.get(
        `/ecommerce/mobile/api/v1/payments/async-task/${asyncTaskId}`
      )

      if (response.asyncTaskStatus === AsyncTaskStatuses.failed) {
        return rejectWithValue('Failed to pay for order')
      } else if (response.asyncTaskStatus === AsyncTaskStatuses.successful) {
        return { status: AsyncTaskStatuses.successful }
      }

      setTimeout(() => {
        dispatch(checkOrderPaymentStatus({ asyncTaskId }))
      }, 2000)

      return { status: AsyncTaskStatuses.pending }
    } catch (error) {
      return rejectWithValue(error)
    }
  }
)

export const payForLabOrderAction = createAsyncThunk<
  void,
  { customerOrderId: LabOrderDraft['id']; paymentMethodId: string }
>(
  'testOrdering/payForLabOrderAction',
  async (
    { customerOrderId, paymentMethodId },
    { rejectWithValue, dispatch }
  ) => {
    try {
      const response: { id: number } = await serverAxios.post(
        `/ecommerce/mobile/api/v1/payments/async-task`,
        {
          customerOrderId,
          paymentMethodId,
        }
      )

      dispatch(checkOrderPaymentStatus({ asyncTaskId: response.id }))
      return
    } catch (error) {
      return rejectWithValue(error)
    }
  }
)

export const getLabOrderReceipt = createAsyncThunk<
  string,
  { id: LabOrderDraft['id'] }
>('testOrdering/getLabOrderReceipt', async ({ id }) => {
  const response: any = await serverAxios.get(
    `/ecommerce/webview/api/v1/orders/${id}`
  )

  return response.receiptUrl
})

interface State {
  orderData: LabProduct | null
  fetchingOrderData: LoadingContext

  labOrderDraft: LabOrderDraft | null
  creatingLabOrderDraft: LoadingContext

  updatingLabOrderDraftAddress: LoadingContext

  updatingLabOrderDraftBillingType: LoadingContext

  confirmingLabOrderDraft: LoadingContext

  payingForLabOrder: LoadingContext

  consultationExistence: boolean

  labOrderReceipt: string | null
  fetchingLabOrderReceipt: LoadingContext

  confirmedOrderId: number | null
}

const initialState: State = {
  orderData: null,
  fetchingOrderData: LoadingContextDefault,

  labOrderDraft: null,
  creatingLabOrderDraft: LoadingContextDefault,

  updatingLabOrderDraftAddress: LoadingContextDefault,

  updatingLabOrderDraftBillingType: LoadingContextDefault,

  confirmingLabOrderDraft: LoadingContextDefault,

  payingForLabOrder: LoadingContextDefault,

  consultationExistence: true,

  labOrderReceipt: null,
  fetchingLabOrderReceipt: LoadingContextDefault,

  confirmedOrderId: null,
}

const slice = createSlice({
  name: 'testOrdering',
  initialState,
  reducers: {
    setConsultationExistence: (state, action) => {
      state.consultationExistence = action.payload
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getLabOrderDataAction.pending, (state) => {
      state.fetchingOrderData = makeLoading(LoadingState.loading)
    })
    builder.addCase(getLabOrderDataAction.fulfilled, (state, action) => {
      state.fetchingOrderData = makeLoading(LoadingState.success)
      state.orderData = action.payload.product
    })
    builder.addCase(getLabOrderDataAction.rejected, (state, action) => {
      state.fetchingOrderData = makeLoading(
        LoadingState.failure,
        extractErrorMessage(action.payload as AxiosError)
      )
    })
    builder.addCase(createLabOrderDraftAction.pending, (state) => {
      state.creatingLabOrderDraft = makeLoading(LoadingState.loading)
    })
    builder.addCase(createLabOrderDraftAction.fulfilled, (state, action) => {
      state.creatingLabOrderDraft = makeLoading(LoadingState.success)
      state.labOrderDraft = action.payload
    })
    builder.addCase(createLabOrderDraftAction.rejected, (state, action) => {
      state.creatingLabOrderDraft = makeLoading(
        LoadingState.failure,
        extractErrorMessage(action.payload as AxiosError)
      )
    })
    builder.addCase(updateLabOrderDraftAddressAction.pending, (state) => {
      state.updatingLabOrderDraftAddress = makeLoading(LoadingState.loading)
    })
    builder.addCase(updateLabOrderDraftAddressAction.fulfilled, (state) => {
      state.updatingLabOrderDraftAddress = makeLoading(LoadingState.success)
    })
    builder.addCase(
      updateLabOrderDraftAddressAction.rejected,
      (state, action) => {
        state.updatingLabOrderDraftAddress = makeLoading(
          LoadingState.failure,
          extractErrorMessage(action.payload as AxiosError)
        )
      }
    )
    builder.addCase(updateLabOrderDraftBillingTypeAction.pending, (state) => {
      state.updatingLabOrderDraftBillingType = makeLoading(LoadingState.loading)
    })
    builder.addCase(updateLabOrderDraftBillingTypeAction.fulfilled, (state) => {
      state.updatingLabOrderDraftBillingType = makeLoading(LoadingState.success)
    })
    builder.addCase(
      updateLabOrderDraftBillingTypeAction.rejected,
      (state, action) => {
        state.updatingLabOrderDraftBillingType = makeLoading(
          LoadingState.failure,
          extractErrorMessage(action.payload as AxiosError)
        )
      }
    )
    builder.addCase(confirmLabOrderDraftAction.pending, (state) => {
      state.confirmingLabOrderDraft = makeLoading(LoadingState.loading)
    })
    builder.addCase(
      confirmLabOrderDraftAction.fulfilled,
      (state, { payload: { labOrderId } }) => {
        state.confirmedOrderId = labOrderId
        state.confirmingLabOrderDraft = makeLoading(LoadingState.success)
      }
    )
    builder.addCase(confirmLabOrderDraftAction.rejected, (state, action) => {
      state.confirmingLabOrderDraft = makeLoading(
        LoadingState.failure,
        extractErrorMessage(action.payload as AxiosError)
      )
    })
    builder.addCase(payForLabOrderAction.pending, (state) => {
      state.payingForLabOrder = makeLoading(LoadingState.loading)
    })
    builder.addCase(checkOrderPaymentStatus.fulfilled, (state, { payload }) => {
      if (payload.status === AsyncTaskStatuses.successful) {
        state.payingForLabOrder = makeLoading(LoadingState.success)
      }
    })
    builder.addCase(payForLabOrderAction.rejected, (state, action) => {
      state.payingForLabOrder = makeLoading(
        LoadingState.failure,
        extractErrorMessage(action.payload as AxiosError)
      )
    })
    builder.addCase(checkOrderPaymentStatus.rejected, (state, action) => {
      state.payingForLabOrder = makeLoading(
        LoadingState.failure,
        extractErrorMessage(action.payload as AxiosError)
      )
    })
    builder.addCase(getLabOrderReceipt.pending, (state) => {
      state.fetchingLabOrderReceipt = makeLoading(LoadingState.loading)
    })
    builder.addCase(getLabOrderReceipt.fulfilled, (state, action) => {
      state.fetchingLabOrderReceipt = makeLoading(LoadingState.success)
      state.labOrderReceipt = action.payload
    })
    builder.addCase(getLabOrderReceipt.rejected, (state, action) => {
      state.fetchingLabOrderReceipt = makeLoading(
        LoadingState.failure,
        extractErrorMessage(action.payload as AxiosError)
      )
    })
  },
})

export const { setConsultationExistence } = slice.actions

export default slice.reducer
