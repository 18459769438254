import { ActionType, createReducer } from 'typesafe-actions'
import moment from 'moment-timezone'
import { Token } from '@stripe/stripe-js'

import appointmentActions from 'src/store/appointment/actions'
import {
  Appointment,
  AvailableSlotsInfo,
  CopayInfo,
  PaymentMethod,
  ReasonForConsultation,
  ScheduledAppointment,
} from '../models'
import { IndicatedPaymentMethod } from '../types'
import { insuranceActions } from '../../insurance/actions'

interface State {
  appointment: Appointment
  stripeCardTokenResponse: Token | null
  availableSlots: AvailableSlotsInfo
  isEligibleZip: boolean
  sourceWidgetType: string
  selectedPaymentMethod: IndicatedPaymentMethod | null
  productById: { [key: string]: { nickname: string; priceDecimal: number } }
  stripePublicKey: string | null
  scheduledAppointment: ScheduledAppointment | null
  savedPaymentId: string | null
  pendingAppointment: ScheduledAppointment | null
  paymentMethods: PaymentMethod[]
  copayStatus: boolean
  copayInfo: CopayInfo | null
  reasonsForConsultation: ReasonForConsultation[]
}

const initialState: State = {
  appointment: {} as any,
  stripeCardTokenResponse: null,
  availableSlots: {
    nextDateHasFreeSlots: true,
    currentDateHasFreeSlots: true,
    currentDate: [],
    nextDate: [],
  },
  isEligibleZip: false,
  sourceWidgetType: '',
  productById: {},
  selectedPaymentMethod: null,
  stripePublicKey: null,
  scheduledAppointment: null,
  savedPaymentId: null,
  pendingAppointment: null,
  paymentMethods: [],
  copayStatus: false,
  copayInfo: null,
  reasonsForConsultation: [],
}

export default createReducer<
  State,
  ActionType<typeof appointmentActions | typeof insuranceActions>
>(initialState)
  .handleAction(
    appointmentActions.updateClinic,
    (state, { payload: { clinic } }): State => ({
      ...state,
      appointment: {
        ...(state.appointment.dateTime
          ? { dateTime: state.appointment.dateTime }
          : {}),
        symptomatic: state.appointment.symptomatic,
        clinic,
      } as any,
    })
  )
  .handleAction(
    appointmentActions.updateAppointmentTime,
    (state, { payload: { dateTime } }): State => ({
      ...state,
      appointment: { ...state.appointment, dateTime },
    })
  )
  .handleAction(
    appointmentActions.clearAppointmentTime,
    (state): State => ({
      ...state,
      appointment: { ...state.appointment, dateTime: null } as any,
    })
  )
  .handleAction(
    appointmentActions.markApptAsSymptomatic,
    (state, { payload }) => ({
      ...state,
      appointment: { ...state.appointment, symptomatic: payload },
    })
  )
  .handleAction(
    [
      appointmentActions.getAvailableSlots.request,
      appointmentActions.getInClinicAvailableSlots.request,
    ],
    (state): State => ({
      ...state,
      availableSlots: {
        currentDate: [],
        nextDate: [],
        nextDateHasFreeSlots: true,
        currentDateHasFreeSlots: true,
      },
    })
  )
  .handleAction(
    [
      appointmentActions.getAvailableSlots.success,
      appointmentActions.getInClinicAvailableSlots.success,
    ],
    (state, { payload: { slotsInfo } }): State => {
      const {
        nextDate,
        currentDateHasFreeSlots,
        nextDateHasFreeSlots,
      } = slotsInfo

      const usaTimezone = 'America/Los_Angeles'
      const currentTime = moment().tz(usaTimezone)

      const currentDate = (slotsInfo.currentDate || []).filter(({ start }) =>
        moment.tz(start, usaTimezone).isAfter(currentTime)
      )

      return {
        ...state,
        availableSlots: {
          currentDate,
          nextDate,
          currentDateHasFreeSlots,
          nextDateHasFreeSlots,
        },
      }
    }
  )

  .handleAction(
    appointmentActions.getClinicByZip.success,
    (state, { payload: { eligible } }): State => ({
      ...state,
      isEligibleZip: eligible,
    })
  )
  .handleAction(
    appointmentActions.preselectAppointment,
    (state, { payload: { appointment } }): State => ({
      ...state,
      appointment: { ...(state.appointment || {}), ...appointment },
    })
  )
  .handleAction(
    appointmentActions.preselectSourceWidgetType,
    (state, { payload: { sourceWidgetType } }): State => ({
      ...state,
      sourceWidgetType,
    })
  )
  .handleAction(
    appointmentActions.addWillNotUseMobilePhoneFlag,
    (state, { payload: { flag } }): State => ({
      ...state,
      appointment: { ...state.appointment, withoutMobileApp: flag },
    })
  )
  .handleAction(
    appointmentActions.getProductById.success,
    (state, { payload: { productId, product } }): State => ({
      ...state,
      productById: {
        ...state.productById,
        [productId]: product,
      },
    })
  )

  .handleAction(
    appointmentActions.setSelectedPaymentMethod,
    (state, { payload }): State => ({
      ...state,
      selectedPaymentMethod: payload,
    })
  )

  .handleAction(
    appointmentActions.getStripePublicKey.success,
    (state, { payload }): State => ({
      ...state,
      stripePublicKey: payload,
    })
  )

  .handleAction(
    appointmentActions.createPaymentToken.success,
    (state, { payload }): State => ({
      ...state,
      stripeCardTokenResponse: payload,
    })
  )
  .handleAction(
    appointmentActions.savePaymentMethod.success,
    (state, { payload }): State => ({
      ...state,
      savedPaymentId: payload,
    })
  )
  .handleAction(
    appointmentActions.selectPaymentMethod,
    (state, { payload: { paymentMethod } }): State => ({
      ...state,
      savedPaymentId: paymentMethod,
    })
  )
  .handleAction(
    appointmentActions.scheduleAppointment.success,
    (state, { payload }): State => ({
      ...state,
      scheduledAppointment: payload,
    })
  )
  .handleAction(
    appointmentActions.checkForPendingAppointment.success,
    (state, { payload }): State => ({
      ...state,
      pendingAppointment: payload,
    })
  )
  .handleAction(
    appointmentActions.checkApptPaymentStatus.success,
    (state, { payload: { appointmentId } }): State => ({
      ...state,
      scheduledAppointment: {
        ...state.scheduledAppointment!,
        id: appointmentId,
      },
    })
  )
  .handleAction(
    appointmentActions.getPaymentMethods.success,
    (state, { payload: { methods } }): State => ({
      ...state,
      paymentMethods: methods,
    })
  )
  .handleAction(
    appointmentActions.getCopayStatus.success,
    (state, { payload: { copayStatus } }): State => ({
      ...state,
      copayStatus,
    })
  )
  .handleAction(
    appointmentActions.getCopayInfo.success,
    (state, { payload: { copayInfo } }): State => ({
      ...state,
      copayInfo,
    })
  )
  .handleAction(
    insuranceActions.verifyInsurance.success,
    (state) => ({
      ...state,
      copayStatus: initialState.copayStatus,
      copayInfo: initialState.copayInfo,
    })
  )
  .handleAction(
    appointmentActions.getReasonsForConsultation.success,
    (state, { payload: { reasonsForConsultation } }): State => ({
      ...state,
      reasonsForConsultation,
    })
  )
