import { createSelector, Selector } from 'reselect'

import { State } from 'src/store/rootReducer'
import { OfficeAllyInfo } from './models'

const getInsuranceEntities = (state: State): State['insurance']['entities'] =>
  state.insurance.entities

const getInsuranceUI = (state: State): State['insurance']['ui'] =>
  state.insurance.ui

export const getFetchingCompanies = createSelector(
  getInsuranceUI,
  (ui) => ui.fetchingCompanies
)

export const getInsuranceCompanies = createSelector(
  getInsuranceEntities,
  (entity) => entity.companiesList.map((name) => entity.companiesByName[name])
)

export const getInsuranceVerifying = createSelector(
  getInsuranceUI,
  (ui) => ui.insuranceVerifying
)

export const getFetchingPatientOfficeAllyInfo = createSelector(
  getInsuranceUI,
  (ui) => ui.fetchingPatientOfficeAllyInfo
)

export const getPatientOfficeAllyInfoSelector: Selector<
  State,
  OfficeAllyInfo | null
> = createSelector(getInsuranceEntities, (entity) => entity.officeAllyInfo)
