import React, { useEffect, useRef, useState } from 'react'
import {
  Box,
  Button,
  CircularProgress,
  styled,
  Typography,
  Stack,
} from '@mui/material'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import RefreshIcon from '@mui/icons-material/Refresh'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'

import ThemedButton from 'src/components/common/ThemedButton'
import AuthLayout from '../../components/layouts/AuthLayout'
import Signature, { SignatureRef } from '../../components/common/Signature'
import FooterButtons from '../../components/FooterButtons'
import { RoutePath } from '../../routes'
import theme from '../../utils/theme'
import {
  getESignatureStatusData,
  getESignatureStatusGetting,
  getESignatureSubmitting,
  getUserProfile,
} from '../../store/auth/selectors'
import { useIsLabOrderingFlow } from '../../hooks/labOrdering/useIsLabOrderingFlow'
import authActions from '../../store/auth/actions'
import { getLoadingState } from '../../utils/types'
import { dispatch } from '../../store'
import useLoadingFailureAlert from '../../hooks/useLoadingFailureAlert'
import useLoadingSuccess from '../../hooks/useLoadingSuccess'

const CustomThemedButton = styled(ThemedButton)(({ theme }) => ({
  borderColor: theme.palette.primary.light,
  justifyContent: 'space-between',
  color: theme.palette.primary.dark,
}))

const TosesAndSignature: React.FC = () => {
  const signature = useRef<SignatureRef>(null)

  const history = useHistory()

  const [hasSignature, setHasSignature] = useState(false)

  const isLabOrderingFlow = useIsLabOrderingFlow()

  const profile = useSelector(getUserProfile)

  const eSignatureStatusData = useSelector(getESignatureStatusData)

  const eSignatureStatusGetting = useSelector(getESignatureStatusGetting)
  const {
    pending: ESignatureStatusGettingPending,
    success: ESignatureStatusGettingSuccess,
    failure: ESignatureStatusGettingFailure,
  } = getLoadingState(eSignatureStatusGetting)

  const eSignatureSubmitting = useSelector(getESignatureSubmitting)
  const { pending: ESignatureStatusSubmittingPending } = getLoadingState(
    eSignatureSubmitting
  )

  const getEsignatureStatus = () => {
    dispatch(authActions.checkESignatureStatus.request())
  }

  useEffect(() => {
    getEsignatureStatus()
  }, [])

  const handleGoNext = () => {
    if (profile?.attachmentIdCardId) {
      history.replace(
        isLabOrderingFlow
          ? RoutePath.appointmentReasons
          : RoutePath.choosePaymentMethod
      )
    } else {
      history.replace(RoutePath.idCard)
    }
  }

  useLoadingSuccess(eSignatureSubmitting, handleGoNext)

  useLoadingFailureAlert(eSignatureSubmitting)

  useEffect(() => {
    if (
      ESignatureStatusGettingSuccess &&
      eSignatureStatusData.length &&
      eSignatureStatusData.every((dataItem) => !dataItem.esignatureExpired)
    ) {
      handleGoNext()
    }
  }, [ESignatureStatusGettingSuccess])

  const handleSubmit = async () => {
    if (!hasSignature) {
      return
    }

    const blob = await signature.current?.getBlob({
      mime: 'image/png',
      quality: 0.8,
    })

    if (!blob) {
      return
    }

    const file = new File([blob], 'signatue.png')

    dispatch(
      authActions.uploadESignature.request({
        signature: file,
        tosTypes: eSignatureStatusData
          .filter((dataItem) => dataItem.esignatureExpired)
          .map((dataItem) => dataItem.tosType),
      })
    )
  }


  return (
    <>
      {ESignatureStatusGettingSuccess && Boolean(eSignatureStatusData.length) && (
        <AuthLayout
          title="Action Urgent Care Policies & Consents"
          bottomActions={
            <FooterButtons
              nextOnly
              nextButtonLabel="Next"
              loadingNext={ESignatureStatusSubmittingPending}
              disableNext={!hasSignature}
              onNextButtonClick={handleSubmit}
            />
          }
        >
          <Box gap={2} marginBottom={3} display="flex" flexDirection="column">
            {eSignatureStatusData.map((dataItem) => (
              <CustomThemedButton
                fullWidth
                thickBorder
                color="primary"
                variant="outlined"
                endIcon={
                  <ArrowForwardIosIcon
                    htmlColor={theme.palette.primary.light}
                  />
                }
                onClick={() => window.open(dataItem.tosLink, '_blank')}
              >
                {dataItem.tosTitle}
              </CustomThemedButton>
            ))}

            <Typography variant="body2" color="primary.dark">
              By signing, you have <b>read and agree</b> to the Scheduling
              Policy, Consent and Authorization, and Notice of Privacy Practices
              above.
            </Typography>
          </Box>

          <Signature
            ref={signature}
            disabled={ESignatureStatusSubmittingPending}
            onChange={(touched) => setHasSignature(touched)}
          />
        </AuthLayout>
      )}

      {(ESignatureStatusGettingPending || ESignatureStatusGettingFailure) && (
        <AuthLayout title="">
          <Box
            display="flex"
            height="100%"
            alignItems="center"
            justifyContent="center"
          >
            {ESignatureStatusGettingPending ? (
              <CircularProgress />
            ) : (
              <Stack spacing={1}>
                <Typography color="error">
                  Something went wrong, please try again
                </Typography>
                <Button
                  endIcon={<RefreshIcon color="primary" />}
                  onClick={getEsignatureStatus}
                >
                  <Typography>Reload</Typography>
                </Button>
              </Stack>
            )}
          </Box>
        </AuthLayout>
      )}
    </>
  )
}

export default TosesAndSignature
