import { Box, CircularProgress, Typography, useTheme } from '@mui/material'
import { Stack } from '@mui/material'
import appointmentActions from '../../store/appointment/actions'
import { getAbsoluteUrl } from '../../utils/general'
import InsuranceLight from '../../assets/icons/labOrdering/InsuranceBold.svg'
import InfoAlert from '../common/info-alert'
import { RoutePath } from '../../routes'
import { useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { IndicatedPaymentMethod } from '../../store/appointment/types'
import { setConsultationExistence } from '../../store/testOrdering'
import { getUpdatingLabOrderDraftBillingType } from '../../store/testOrdering/selectors'
import { isLoading } from '../../utils/types'

export function LabOrderPayInsuranceOption() {
  const theme = useTheme()
  const history = useHistory()
  const dispatch = useDispatch()

  const updatingLabOrderDraftBillingType = useSelector(
    getUpdatingLabOrderDraftBillingType
  )

  // useLoadingSuccess(updatingLabOrderDraftBillingType, () => {
  //   dispatch(
  //     appointmentActions.setSelectedPaymentMethod(
  //       IndicatedPaymentMethod.insurance
  //     )
  //   )

  //   history.push(RoutePath.addSignature)
  // })

  const handleInsurance = () => {
    // dispatch(
    //   updateLabOrderDraftBillingTypeAction({
    //     billingType: 'INSURANCE',
    //     draftId: labOrderDraft!.id,
    //   })
    // )
    dispatch(
      appointmentActions.setSelectedPaymentMethod(
        IndicatedPaymentMethod.insurance
      )
    )

    dispatch(setConsultationExistence(true))

    history.push(RoutePath.addSignature)
  }

  const loading = isLoading(updatingLabOrderDraftBillingType.state)

  return (
    <Stack direction="column" spacing={1.5}>
      <Box>
        <Stack
          flex={1}
          direction="row"
          alignItems="center"
          spacing={2}
          onClick={handleInsurance}
          sx={{
            padding: theme.spacing(1.5, 2),
            background: theme.palette.background.default,
            border: `2px solid ${theme.palette.primary.light}`,
            borderRadius: '20px',
            '&:hover': {
              backgroundColor: theme.palette.primary.light,
              border: `2px solid ${theme.palette.primary.main}`,
              cursor: 'pointer',
            },
          }}
        >
          <Stack>
            <img src={getAbsoluteUrl(InsuranceLight)} width={40} alt="" />
          </Stack>
          <Box flex={1} color="primary.dark">
            {loading ? (
              <CircularProgress size={20} />
            ) : (
              <>
                <Typography sx={{ fontWeight: 500 }}>
                  Or pay with my insurance
                </Typography>
                <Typography sx={{ fontWeight: 500 }}>
                  Insurance coverage may vary
                </Typography>
              </>
            )}
          </Box>
        </Stack>
      </Box>
      <Box>
        <InfoAlert>
          <Typography>
            Insurance-covered lab tests are available only in California and
            require a doctor consultation.
          </Typography>
        </InfoAlert>
      </Box>
    </Stack>
  )
}
