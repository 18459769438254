import React, { useCallback } from 'react'
import { Box, Stack, Typography } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import clsx from 'clsx'
import { useHistory } from 'react-router-dom'

import AuthLayout from 'src/components/layouts/AuthLayout'
import { getAbsoluteUrl } from '../../utils/general'
import ConsultationIcon from '../../assets/icons/labOrdering/ConsultationIcon.svg'
import { RoutePath } from '../../routes'
import { useDispatch } from '../../store'
import { setConsultationExistence } from '../../store/testOrdering'
import FooterButtons from '../../components/FooterButtons'

const useStyles = makeStyles(
  (theme) => ({
    text: {
      fontFamily: 'NeurialGrotesk',
      color: theme.palette.primary.dark,
    },
    title: {
      lineHeight: '25px',
      fontSize: '20px',
      fontWeight: 700,
    },
    californiaResidents: {
      width: 'fit-content',
      padding: theme.spacing(0.5, 0.75),
      borderRadius: 11,
      background: 'rgba(0, 163, 255, 0.3)',
    },
    smallText: {
      lineHeight: 1,
      fontSize: '13px',
      color: theme.palette.primary.main,
      opacity: 0.6,
    },
  }),
  { name: 'ChooseConsultationExistence' }
)

const ChooseConsultationExistence: React.FC = () => {
  const classes = useStyles()
  const history = useHistory()
  const dispatch = useDispatch()

  const handleProceedWithout = useCallback(() => {
    dispatch(setConsultationExistence(false))

    history.push(RoutePath.choosePaymentCard)
  }, [dispatch, history])

  const handleProceedWith = useCallback(() => {
    history.push(RoutePath.appointmentTime)
  }, [history])

  return (
    <AuthLayout
      bottomActions={
        <FooterButtons
          nextButtonLabel="Add Consultation"
          backButtonLabel="Proceed Without"
          onNextButtonClick={handleProceedWith}
          onBackButtonClick={handleProceedWithout}
        />
      }
    >
      <Stack spacing={1.5}>
        <img
          src={getAbsoluteUrl(ConsultationIcon)}
          height={100}
          alt="consultation-icon"
        />

        <Typography className={clsx(classes.text, classes.title)}>
          Enhance the value of your lab tests by scheduling a doctor
          consultation
        </Typography>

        <Box
          display="flex"
          alignItems="center"
          className={classes.californiaResidents}
        >
          <Typography className={clsx(classes.text, classes.smallText)}>
            California Residents Only
          </Typography>
        </Box>

        <Typography className={classes.text}>
          A consultation will help determine the necessary tests for your
          health.
        </Typography>
      </Stack>
    </AuthLayout>
  )
}

export default ChooseConsultationExistence
