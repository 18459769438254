import React, { useCallback, useImperativeHandle, useEffect } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { FormControl, Grid, Theme } from '@mui/material'
import { Moment } from 'moment'
import makeStyles from '@mui/styles/makeStyles'

import ThemedTextField from '../common/ThemedTextField'
import ThemedDatePicker from '../common/ThemedDatePicker'
import { InsuranceCompany } from '../../store/insurance/models'
import { validateMinDob } from '../../utils/general'
import ThemedImagePicker from '../common/ThemedImagePicker'

const useStyles = makeStyles(
  (theme: Theme) => ({
    formInput: {
      width: '100%',
      marginTop: theme.spacing(1.25),
      '&:first-child': {
        marginTop: theme.spacing(1.5),
      },
    },
    formControl: {
      width: '100%',
      marginTop: theme.spacing(1.25),
    },
    insuranceImagePicker: {
      height: 150,
    },
  }),
  { name: 'InsuranceInfoForm' }
)

export type InsuranceInfoFormValues = {
  insuranceName: string
  insurancePayerId: string
  insuranceId: string
  firstName: string
  lastName: string
  dob: Moment
  front: File | null
  back: File | null
}

export interface InsuranceInfoFormHandlers {
  submit(): void
  updateCompany(company: InsuranceCompany): void
}

interface InsuranceInfoFormProps {
  initialValues?: InsuranceInfoFormValues
  onSubmit(data: InsuranceInfoFormValues): void
  onSearchListShow(): void
}

const InsuranceInfoForm = React.forwardRef<
  InsuranceInfoFormHandlers,
  InsuranceInfoFormProps
>(({ onSubmit, onSearchListShow, initialValues }, ref) => {
  const classes = useStyles()

  const {
    register,
    handleSubmit,
    errors,
    control,
    watch,
    reset,
    setValue,
  } = useForm<InsuranceInfoFormValues>({
    mode: 'onChange',
    shouldFocusError: false,
    defaultValues: initialValues,
  })
  const values = watch()

  useEffect(() => {
    reset(initialValues)
  }, [JSON.stringify(initialValues)])

  const handleNewAccountSubmit = useCallback(
    (values: InsuranceInfoFormValues) => {
      onSubmit(values)
    },
    [onSubmit]
  )

  const handleCompanyValueUpdate = useCallback(
    (insuranceCompany: InsuranceCompany) => {
      setValue('insuranceName', insuranceCompany.name)
      setValue('insurancePayerId', insuranceCompany.id)
    },
    [setValue]
  )

  useImperativeHandle(ref, () => ({
    submit: handleSubmit(handleNewAccountSubmit),
    updateCompany: handleCompanyValueUpdate,
  }))

  return (
    <Grid>
      <ThemedTextField
        name="insuranceName"
        autoComplete="off"
        inputRef={register({ required: true })}
        classes={{ input: classes.formInput }}
        color="primary"
        placeholder="Insurance Name"
        error={Boolean(errors.insuranceName)}
        onFocus={onSearchListShow}
      />

      <ThemedTextField
        name="insurancePayerId"
        autoComplete="off"
        inputRef={register({ required: true })}
        classes={{ input: classes.formInput }}
        color="primary"
        placeholder="Insurance Payer ID"
        error={Boolean(errors.insurancePayerId)}
        onFocus={onSearchListShow}
      />

      <ThemedTextField
        name="insuranceId"
        autoComplete="off"
        inputRef={register({ required: true })}
        classes={{ input: classes.formInput }}
        color="primary"
        placeholder="Insurance ID"
        error={Boolean(errors.insuranceId)}
      />

      <ThemedTextField
        name="firstName"
        autoComplete="off"
        inputRef={register({ required: true })}
        classes={{ input: classes.formInput }}
        color="primary"
        placeholder="First Name"
        error={Boolean(errors.firstName)}
      />

      <ThemedTextField
        name="lastName"
        autoComplete="off"
        inputRef={register({ required: true })}
        classes={{ input: classes.formInput }}
        color="primary"
        placeholder="Last Name"
        error={Boolean(errors.lastName)}
      />

      <FormControl className={classes.formControl}>
        <Controller
          as={<ThemedDatePicker error={Boolean(errors.dob)} />}
          control={control}
          name="dob"
          defaultValue={values.dob || null}
          rules={{
            required: true,
            validate: {
              validDate: (value: string) => validateMinDob(value),
            },
          }}
        />
      </FormControl>

      <Grid container spacing={1}>
        <Grid item xs={6} container direction="column">
          <Controller
            control={control}
            name="front"
            render={(props) => (
              <ThemedImagePicker
                classes={{ root: classes.insuranceImagePicker }}
                label="Insurance card front"
                image={values.front}
                onImageAdd={(file: File) => setValue(props.name, file)}
              />
            )}
          />
        </Grid>

        <Grid item xs={6} container direction="column">
          <Controller
            control={control}
            name="back"
            render={(props) => (
              <ThemedImagePicker
                classes={{ root: classes.insuranceImagePicker }}
                label="Insurance card back"
                image={values.back}
                onImageAdd={(file: File) => setValue(props.name, file)}
              />
            )}
          />
        </Grid>
      </Grid>
    </Grid>
  )
})

export default InsuranceInfoForm
