import React, { useCallback, useEffect, useRef, useState, useMemo } from 'react'
import { Typography, Box, Stack, Divider } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

import AuthLayout from '../../components/layouts/AuthLayout'
import FooterButtons from '../../components/FooterButtons'
import CreditCardForm, {
  CreditCardFormActions,
  CreditCardFormProps,
} from '../../components/CreditCardForm'
import {
  getAppointmentProductId,
  getCopayInfoSelector,
  getCreatePaymentTokenSubmitting,
  getPayForAppointmentSubmitting,
  getSavedPaymentId,
  getSavePaymentMethodSubmitting,
  getSelectedPaymentMethod,
  getStripeCardTokenResponse,
  getStripePublicKeyLoading,
  useProductById,
} from '../../store/appointment/selectors'
import appointmentActions from '../../store/appointment/actions'
import { getLoadingState } from '../../utils/types'
import { IndicatedPaymentMethod } from '../../store/appointment/types'
import { RoutePath } from '../../routes'
import useLoadingSuccess from '../../hooks/useLoadingSuccess'
import useLoadingFailureAlert from '../../hooks/useLoadingFailureAlert'
import useLoadingFailure from '../../hooks/useLoadingFailure'
import gtag from '../../utils/gtag'
import { extractErrorMessage } from '../../utils/errors'
import IOSSwitch from '../../components/common/IOSSwitch'
import { useIsLabOrderingFlow } from '../../hooks/labOrdering/useIsLabOrderingFlow'
import { useLabOrderData } from '../../hooks/labOrdering/useLabOrderData'
import {
  getConsultationExistence,
  getPayingForLabOrder,
} from '../../store/testOrdering/selectors'
import { payForLabOrderAction } from '../../store/testOrdering'
import { useLabOrderDraftCreate } from '../../hooks/labOrdering/useLabOrderDraftCreate'
import {
  trackAddPaymentInfo,
  trackLabPaymentConfirmation,
} from '../../utils/analytics'
import { LabOrderPayInsuranceOption } from '../../components/labOrdering/lab-order-pay-insurance-option'

const useStyles = makeStyles((theme) => ({
  content: {
    display: 'flex',
  },
  hint: {
    color: theme.palette.grey[500],
  },
  switchContainer: {
    marginTop: theme.spacing(2),
  },
  switchLabel: {
    color: 'rgba(0, 0, 0, 0.6)',
  },
}))

const AddPaymentMethod: React.FC = () => {
  const dispatch = useDispatch()
  const classes = useStyles()
  const history = useHistory()

  const isLabOrderingFlow = useIsLabOrderingFlow()

  const consultationExistence = useSelector(getConsultationExistence)

  const { order } = useLabOrderData()

  const creditCardForm = useRef<CreditCardFormActions>(null)

  const [saveCard, setSaveCard] = useState(false)

  const { labOrderDraft } = useLabOrderDraftCreate()

  const paymentMethodId = useSelector(getSavedPaymentId)

  const payingForLabOrder = useSelector(getPayingForLabOrder)

  const stripeCardTokenResponse = useSelector(getStripeCardTokenResponse)
  const selectedPaymentMethod = useSelector(getSelectedPaymentMethod)
  const isCashPayment = selectedPaymentMethod === IndicatedPaymentMethod.cash

  const savePaymentMethodSubmitting = useSelector(
    getSavePaymentMethodSubmitting
  )
  const appointmentProductId = useSelector(getAppointmentProductId)
  const { product: consultationProduct } = useProductById(appointmentProductId)

  const stripePublicKeyLoading = useSelector(getStripePublicKeyLoading)
  const createPaymentTokenSubmitting = useSelector(
    getCreatePaymentTokenSubmitting
  )

  const payForAppointmentSubmitting = useSelector(
    getPayForAppointmentSubmitting
  )

  const createPaymentTokenSubmittingState = getLoadingState(
    createPaymentTokenSubmitting
  )
  const stripePublicKeyLoadingState = getLoadingState(stripePublicKeyLoading)

  const savePaymentMethodSubmittingState = getLoadingState(
    savePaymentMethodSubmitting
  )

  const payForAppointmentSubmittingState = getLoadingState(
    payForAppointmentSubmitting
  )

  const payingForLabOrderState = getLoadingState(payingForLabOrder)

  const copayInfo = useSelector(getCopayInfoSelector)

  const loadStripePublicKey = useCallback(() => {
    dispatch(appointmentActions.getStripePublicKey.request())
  }, [dispatch])

  useEffect(loadStripePublicKey, [loadStripePublicKey])

  const triggerSubmit = useCallback(() => {
    creditCardForm.current?.submit?.()
  }, [])

  const handleSubmit = useCallback<CreditCardFormProps['onSubmit']>(
    (values) => {
      dispatch(appointmentActions.createPaymentToken.request(values))
    },
    [dispatch]
  )

  const handleBackClick = useCallback(() => {
    history.goBack()
  }, [history])

  useLoadingSuccess(createPaymentTokenSubmitting, () => {
    if (!stripeCardTokenResponse) {
      return
    }

    dispatch(
      appointmentActions.savePaymentMethod.request({
        ...stripeCardTokenResponse,
        saveCard,
      })
    )
  })

  useLoadingFailureAlert(createPaymentTokenSubmitting)

  useLoadingFailure(createPaymentTokenSubmitting, ({ error }) => {
    gtag('event', 'webView_create_payment_method_failed', {
      message: extractErrorMessage(error),
    })
  })

  const handleSavePaymentMethodForLabOrder = useCallback(() => {
    dispatch(
      payForLabOrderAction({
        customerOrderId: labOrderDraft!.id,
        paymentMethodId: paymentMethodId!,
      })
    )
  }, [dispatch, paymentMethodId, labOrderDraft])

  const handleSavePaymentMethodForConsultation = useCallback(() => {
    if ((isCashPayment || copayInfo) && !isLabOrderingFlow) {
      dispatch(appointmentActions.payForAppointment.request())
    } else {
      history.push(RoutePath.createAppointment)
    }
  }, [dispatch, history, isCashPayment, copayInfo, isLabOrderingFlow])

  useLoadingSuccess(savePaymentMethodSubmitting, () => {
    if (consultationExistence) {
      handleSavePaymentMethodForConsultation()
    } else {
      handleSavePaymentMethodForLabOrder()
    }

    trackAddPaymentInfo()
  })

  useLoadingFailure(savePaymentMethodSubmitting, ({ error }) => {
    gtag('event', 'webView_save_payment_method_failed', {
      message: extractErrorMessage(error),
    })
  })

  useLoadingFailureAlert(savePaymentMethodSubmitting)

  useLoadingFailure(payForAppointmentSubmitting, ({ error }) => {
    gtag('event', 'webView_attach_payment_method_failed', {
      message: extractErrorMessage(error),
    })
  })

  useLoadingSuccess(payForAppointmentSubmitting, () => {
    history.replace(RoutePath.paymentSuccessful)
  })

  useLoadingFailureAlert(payForAppointmentSubmitting)

  useLoadingSuccess(payingForLabOrder, () => {
    history.replace(RoutePath.labOrderPaymentSuccessful)

    if (order) {
      trackLabPaymentConfirmation({ labId: order.id, price: order.price })
    }
  })

  useLoadingFailureAlert(payingForLabOrder)

  const pageIsLoading = useMemo(
    () =>
      stripePublicKeyLoadingState.pending ||
      createPaymentTokenSubmittingState.pending ||
      savePaymentMethodSubmittingState.pending ||
      payForAppointmentSubmittingState.pending ||
      payingForLabOrderState.pending,
    [
      stripePublicKeyLoadingState,
      createPaymentTokenSubmittingState,
      savePaymentMethodSubmittingState,
      payForAppointmentSubmittingState,
      payingForLabOrderState,
    ]
  )

  return (
    <AuthLayout
      title={
        isLabOrderingFlow && order && !consultationExistence
          ? `To Pay: $${order.price}`
          : isCashPayment
          ? `To Pay: $${
              copayInfo
                ? copayInfo!.paymentAmount
                : consultationProduct?.priceDecimal
            }`
          : 'Add Card Information'
      }
      bottomActions={
        <FooterButtons
          nextButtonLabel="Next"
          backButtonLabel="Back"
          onNextButtonClick={triggerSubmit}
          onBackButtonClick={handleBackClick}
          disableNext={pageIsLoading}
          disableBack={pageIsLoading}
          loadingNext={
            createPaymentTokenSubmittingState.pending ||
            savePaymentMethodSubmittingState.pending ||
            payForAppointmentSubmittingState.pending ||
            payingForLabOrderState.pending
          }
        />
      }
      contentClass={classes.content}
    >
      <Stack spacing={1}>
        <Typography color="primary.dark">
          {isCashPayment
            ? ''
            : 'Credit card information is required for any deductible, copay or cancellation fees that may apply.'}
        </Typography>

        <Stack spacing={2}>
          {stripePublicKeyLoadingState.success && (
            <CreditCardForm
              disabled={pageIsLoading}
              ref={creditCardForm}
              onSubmit={handleSubmit}
            />
          )}

          {!isLabOrderingFlow && (
            <Box>
              <Typography
                className={classes.hint}
                variant="body2"
                color="primary"
              >
                {isCashPayment
                  ? 'You have 15 minutes to pay for a service or your appointment will be canceled.'
                  : 'Please Note: We need this card on file in case you do not use the service based on Kyla policy.'}
              </Typography>
            </Box>
          )}

          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            className={classes.switchContainer}
          >
            <Typography className={classes.switchLabel}>Save Card</Typography>

            <IOSSwitch
              disabled={pageIsLoading}
              checked={saveCard}
              onChange={() => setSaveCard(!saveCard)}
            />
          </Box>

          <Box>
            <Divider />
          </Box>

          {isCashPayment && isLabOrderingFlow && (
            <Box>
              <LabOrderPayInsuranceOption />
            </Box>
          )}
        </Stack>
      </Stack>
    </AuthLayout>
  )
}

export default AddPaymentMethod
