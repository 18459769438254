export type DuplicationData = {
  kylaId: number
  kylaEmail: string
  duplicationResultId: number
  verificationInfo: {
    id: number
    email: string
    phone: string
  }
}

export interface UsersProfile {
  active: boolean
  street: string
  city: string
  state: string
  zipCode: string
  attachmentIdCardId: number | null
  clearedForWorkStatus: string
  companyId: number
  companyName: string
  covidStatusDescription: string
  dob: string
  dateOfBirth: string
  duplicate: boolean
  duplicatePatientId: number
  email: string
  employee: boolean
  employeeClearedForWorkStatus: string
  firstName: string
  gender: string
  id: number
  insuranceGroupNumber: number
  insuranceId: string
  insuranceName: string
  isInsured: boolean
  lastName: string
  middleName: string
  phoneNumber: string
  phoneVerified: boolean
  publicCompanyId: number
  qrCodeIdLink: string
  roles: string[]
  timezoneOffset: number
  userName: string
  analyticId: string
  insuranceInfo?: {
    isEligible: boolean
    insuranceId: string
    insuranceName: string
    insurancePayerId: string
    attachmentBackPhotoId: number
    attachmentFrontPhotoId: number
    insuranceTypeCode?: {
      fullName: string
    }
  }
}

export interface UpdateUserProfile {
  address: string
  zipCode: string
  city: string
  state: string
}

export enum VerificationOptionsTypes {
  email = 'EMAIL',
  phone = 'PHONE',
}

export const VerificationOptionValues: Record<
  string,
  VerificationOptionsTypes
> = {
  email: VerificationOptionsTypes.email,
  phone: VerificationOptionsTypes.phone,
}

export enum TypeOfSkip {
  noAccess = 'DONT_HAVE_ACCESS',
  notMineAccount = 'NOT_MINE_ACCOUNT',
  callSupport = 'CONTACT_SUPPORT',
}

export type EsignatureTosStatusData = {
  patientId: number
  attachmentId: number | null
  tosLink: string
  tosTitle: string
  esignatureExpired: boolean
  signatureDateTime: string | null
  tosType: 'TOS_PRIVACY_PRACTICE' | string
}
