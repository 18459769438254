import { createAction, createAsyncAction } from 'typesafe-actions'
import { AxiosError } from 'axios'

import { NewAccountFormValues } from '../../components/forms/NewAccountForm'
import {
  UsersProfile,
  VerificationOptionsTypes,
  UpdateUserProfile,
  DuplicationData,
  TypeOfSkip,
  EsignatureTosStatusData,
} from './models'
import { ServerErrorResponse } from '../../utils/api/types'
import { LabOrderingPatientInfo } from '../../utils/labOrdering'

const actions = {
  updateGeneralInfo: createAction('auth/UPDATE_MAIN_INFO')<{
    info: NewAccountFormValues
  }>(),
  signIn: createAsyncAction(
    'auth/SIGN_IN',
    'auth/SIGN_IN_SUCCESS',
    'auth/SIGN_IN_FAILURE'
  )<
    { email: string; password: string },
    { id: number; token: string; profile: UsersProfile; analyticId: string },
    { message: string; error: AxiosError<ServerErrorResponse> }
  >(),
  forgotPassword: createAsyncAction(
    'auth/FORGOT_PASSWORD',
    'auth/FORGOT_PASSWORD_SUCCESS',
    'auth/FORGOT_PASSWORD_FAILURE'
  )<{ email: string }, void, { message: string }>(),
  checkEmail: createAsyncAction(
    'auth/CHECK_EMAIL',
    'auth/CHECK_EMAIL_SUCCESS',
    'auth/CHECK_EMAIL_FAILURE'
  )<
    { email: string },
    { analyticId: string },
    { message: string; error: AxiosError<ServerErrorResponse> }
  >(),
  checkKylaDuplicate: createAsyncAction(
    'auth/CHECK_KYLA_DUPLICATE',
    'auth/CHECK_KYLA_DUPLICATE_SUCCESS',
    'auth/CHECK_KYLA_DUPLICATE_FAILURE'
  )<
    { typeOfSkip: TypeOfSkip | null },
    { temporaryUserId: number },
    { message: string; error: AxiosError<ServerErrorResponse> }
  >(),
  storeKylaDuplicateOptions: createAction('auth/STORE_KYLA_DUPLICATE_OPTIONS')<{
    options: {
      signInIntoExistingAccount: boolean
      reactivateAccount: boolean
    }
  }>(),
  createAccount: createAsyncAction(
    'auth/CREATE_ACCOUNT',
    'auth/CREATE_ACCOUNT_SUCCESS',
    'auth/CREATE_ACCOUNT_FAILURE'
  )<
    { password: string; patientInfo?: LabOrderingPatientInfo },
    { id: number; token: string; profile: UsersProfile },
    { message: string; error: AxiosError<ServerErrorResponse> }
  >(),
  skipChronoDuplicate: createAsyncAction(
    'auth/SKIP_CHRONO_DUPLOCATE',
    'auth/SKIP_CHRONO_DUPLOCATE_SUCCESS',
    'auth/SKIP_CHRONO_DUPLOCATE_FAILURE'
  )<
    { typeOfSkip: TypeOfSkip | null; duplicationLogId: number },
    void,
    { error: AxiosError<ServerErrorResponse> }
  >(),
  resetPassword: createAsyncAction(
    'auth/RESET_PASSWORD',
    'auth/RESET_PASSWORD_SUCCESS',
    'auth/RESET_PASSWORD_FAILURE'
  )<{ password: string; key: string }, void, { message: string }>(),
  logOut: createAction('auth/LOG_OUT')<void>(),
  storeAccountDeletedState: createAction('auth/STORE_ACCOUNT_DELETED_STATE')<{
    accountDeleted: boolean
    accountBlacklisted: boolean
    analyticId: string
  }>(),
  clearAccountDeletedState: createAction('auth/CLEAR_ACCOUNT_DELETED_STATE')<
    void
  >(),
  getVerificationCode: createAsyncAction(
    'auth/GET_VERIFICATION_CODE',
    'auth/GET_VERIFICATION_CODE_SUCCESS',
    'auth/GET_VERIFICATION_CODE_FAILURE'
  )<
    { id: number; verificationType: VerificationOptionsTypes },
    { verificationType: VerificationOptionsTypes },
    { message: string; error: AxiosError<ServerErrorResponse> }
  >(),
  sendVerifyEmailCode: createAsyncAction(
    'auth/SEND_VERIFY_EMAIL_CODE',
    'auth/SEND_VERIFY_EMAIL_CODE_SUCCESS',
    'auth/SEND_VERIFY_EMAIL_CODE_FAILURE'
  )<void, void, { message: string; error: AxiosError<ServerErrorResponse> }>(),
  linkWithChronoAccount: createAsyncAction(
    'auth/LINK_WITH_CHRONO_ACCOUNT',
    'auth/LINK_WITH_CHRONO_ACCOUNT_SUCCESS',
    'auth/LINK_WITH_CHRONO_ACCOUNT_FAILURE'
  )<
    { code: string; id: number; patientId: number },
    void,
    { message: string; error: AxiosError<ServerErrorResponse> }
  >(),
  verifyEmail: createAsyncAction(
    'auth/VERIFY_EMAIL',
    'auth/VERIFY_EMAIL_SUCCESS',
    'auth/VERIFY_EMAIL_FAILURE'
  )<
    { verificationCode: string },
    void,
    { message: string; error: AxiosError<ServerErrorResponse> }
  >(),
  sendReactivationCode: createAsyncAction(
    'auth/SEND_REACTIVATION_CODE',
    'auth/SEND_REACTIVATION_CODE_SUCCESS',
    'auth/SEND_REACTIVATION_CODE_FAILURE'
  )<void, void, { message: string; error: AxiosError<ServerErrorResponse> }>(),
  reactivateAccount: createAsyncAction(
    'auth/REACTIVATE_ACCOUNT',
    'auth/REACTIVATE_ACCOUNT_SUCCESS',
    'auth/REACTIVATE_ACCOUNT_FAILURE'
  )<
    { verificationCode: string },
    { verificationCode: string },
    { message: string; error: AxiosError<ServerErrorResponse> }
  >(),
  updatePassword: createAsyncAction(
    'auth/UPDATE_PASSWORD',
    'auth/UPDATE_PASSWORD_SUCCESS',
    'auth/UPDATE_PASSWORD_FAILURE'
  )<
    { password: string; email: string; reactivationCode: string },
    void,
    { message: string }
  >(),
  checkESignatureStatus: createAsyncAction(
    'auth/CHECK_ESIGNATURE_STATUS_REQUEST',
    'auth/CHECK_ESIGNATURE_STATUS_REQUEST_SUCCESS',
    'auth/CHECK_ESIGNATURE_STATUS_REQUEST_FAILURE'
  )<void, { data: EsignatureTosStatusData[] }, { error: Error }>(),
  uploadESignature: createAsyncAction(
    'auth/UPLOAD_SIGNATURE_REQUEST',
    'auth/UPLOAD_SIGNATURE_SUCCESS',
    'auth/UPLOAD_SIGNATURE_FAILURE'
  )<{ signature: File; tosTypes: string[] }, void, { error: Error }>(),
  createChronoAccount: createAsyncAction(
    'auth/CREATE_CHRONO_ACCOUNT_REQUEST',
    'auth/CREATE_CHRONO_ACCOUNT_SUCCESS',
    'auth/CREATE_CHRONO_ACCOUNT_FAILURE'
  )<void, void, { error: Error }>(),
  getProfile: createAsyncAction(
    'auth/GET_PROFILE_REQUEST',
    'auth/GET_PROFILE_SUCCESS',
    'auth/GET_PROFILE_FAILURE'
  )<void, { profile: UsersProfile }, { error: Error }>(),
  updateProfile: createAsyncAction(
    'auth/UPDATE_PROFILE_REQUEST',
    'auth/UPDATE_PROFILE_SUCCESS',
    'auth/UPDATE_PROFILE_FAILURE'
  )<Partial<UpdateUserProfile>, { profile: UsersProfile }, { error: Error }>(),
  storeDuplicationInfo: createAction('auth/STORE_DUPLICATION_INFO')<{
    duplicationInfo: DuplicationData
  }>(),
  clearDuplicationInfo: createAction('auth/CLEAR_DUPLICATION_INFO')<void>(),
  clearData: createAction('auth/CLEAR_DATA')<void>(),
  clearPatientAddressDetails: createAction(
    'auth/CLEAR_PATIENT_ADDRESS_DETAILS'
  )<void>(),
  uploadIdCard: createAsyncAction(
    'auth/UPLOAD_ID_CARD',
    'auth/UPLOAD_ID_CARD_SUCCESS',
    'auth/UPLOAD_ID_CARD_FAILURE'
  )<{ idCard: File; patientId: number }, void, { error: Error }>(),
  storeOutdatedTos: createAction('auth/STORE_OUTDATED_TOS')<{
    outdatedTosList: string[]
  }>(),
  clearOutdatedTos: createAction('auth/CLEAR_OUTDATED_TOS')<void>(),
  updateTos: createAsyncAction(
    'auth/UPDATE_TOS',
    'auth/UPDATE_TOS_SUCCESS',
    'auth/UPDATE_TOS_FAILURE'
  )<
    {
      guardianInfo?: {
        dateOfBirth: string
        firstName: string
        lastName: string
      }
    },
    { id: number; token: string; profile: UsersProfile },
    { message: string; error: AxiosError<ServerErrorResponse> }
  >(),
}

export default actions
