import Payment from 'payment'
import createAutoCorrectedDatePipe from 'text-mask-addons/dist/createAutoCorrectedDatePipe'

export const CC_EXPIRY_MASK = [/\d/, /\d/, '/', /\d/, /\d/]

export const autoCorrectedDatePipe = createAutoCorrectedDatePipe('mm/yy')

const DEFAULT_CC_MASK = [
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  ' ',
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  ' ',
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  ' ',
  /\d/,
  /\d/,
  /\d/,
  /\d/,
]

const AMEX_CC_MASK = [
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  ' ',
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  ' ',
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
]

const UNKNOWN_CC_MASK = Array(20)
  .fill(null)
  .map(() => /\d/)

const UNKNOWN_CVV_MASK = Array(5)
  .fill(null)
  .map(() => /\d/)

const DEFAULT_CVV_MASK = [/\d/, /\d/, /\d/]
const AMEX_CVV_MASK = [/\d/, /\d/, /\d/, /\d/]

export const getFormMasks = (cardNumber: string) => {
  if (!cardNumber) {
    return {
      creditCard: DEFAULT_CC_MASK,
      cvv: DEFAULT_CVV_MASK,
    }
  }

  switch (Payment.fns.cardType(cardNumber)) {
    case 'amex':
      return {
        creditCard: AMEX_CC_MASK,
        cvv: AMEX_CVV_MASK,
      }
    case 'dinersclub':
      return {
        creditCard: UNKNOWN_CC_MASK,
        cvv: DEFAULT_CVV_MASK,
      }
    case 'unionpay':
    case 'jcb':
    case 'discover':
    case 'mastercard':
    case 'visa':
      return {
        creditCard: DEFAULT_CC_MASK,
        cvv: DEFAULT_CVV_MASK,
      }
    default:
      return {
        creditCard: UNKNOWN_CC_MASK,
        cvv: UNKNOWN_CVV_MASK,
      }
  }
}
