import axios, { AxiosError, AxiosResponse } from 'axios'

import { config } from 'src/utils/config'
import { getAuthToken } from '../../store/auth/selectors'
import { ServerErrorResponse, ServerSuccessResponse } from './types'

const serverAxios = axios.create({
  timeout: 30000,
  responseType: 'json',
  baseURL: `${config?.server.host}`,
})

serverAxios.interceptors.request.use(
  (requestConfig) => {
    const state = window.store.getState()

    const authToken = getAuthToken(state)

    if (authToken) {
      // eslint-disable-next-line no-param-reassign
      requestConfig.headers.Authentication = `Bearer ${authToken}`
    }

    return requestConfig
  }
)

serverAxios.interceptors.response.use(
  (response: AxiosResponse<ServerSuccessResponse>) =>
    response.data.data ?? response.data,
  (error: AxiosError<ServerErrorResponse>) => Promise.reject(error)
)

export { serverAxios }
