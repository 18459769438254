import React, { useCallback, useState } from 'react'
import {
  CircularProgress,
  Grid,
  Theme,
  Typography,
  Box,
  Button,
} from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import clsx from 'clsx'
import { useDispatch, useSelector } from 'react-redux'
import moment from 'moment/moment'
import { useHistory } from 'react-router-dom'

import appointmentActions from 'src/store/appointment/actions'
import { Clinic } from '../../../store/clinics/models'
import {
  getAppointmentClinic,
  getAppointmentTime,
} from '../../../store/appointment/selectors'
import GoogleMap from '../../../components/map/GoogleMap'
import ClinicsListContainer from '../../../containers/ClinicsListContainer'
import OverlayItem from '../../../components/common/OverlayItem'
import HomePageTabs, {
  PageViews,
} from '../../../components/common/HomePageTabs'
import { getAbsoluteUrl } from '../../../utils/general'
import BackButton from '../../../assets/icons/BackButton.svg'
import { useClinicsById } from '../../../hooks/useClinicsById'
import SelectedClinicInfoButton from '../../../components/common/SelectedClinicInfoButton'
import { RoutePath } from '../../../routes'

const useStyles = makeStyles(
  (theme: Theme) => ({
    text: {
      fontFamily: 'Arial',
      color: theme.palette.primary.dark,
      lineHeight: 1.4,
    },
    title: {
      fontWeight: 'bold',
      fontSize: '2.25rem',
      letterSpacing: '-1px',
    },
    timeSlotContainer: {
      padding: theme.spacing(2.5, 4, 2),
      gap: '20px',
    },
    mapContainer: {
      height: 235,
      position: 'relative',
      padding: theme.spacing(1),
    },
    mapView: {
      backgroundColor: theme.palette.primary.light,
      borderRadius: '0 0 40px 40px',
      flex: 1,
      paddingBottom: theme.spacing(4.5),
    },
    appointmentInfo: {
      fontWeight: 700,
      lineHeight: '28px',
    },
    appointmentTime: {
      fontSize: '1.75rem',
      letterSpacing: '-1px',
    },
    appointmentDate: {
      fontSize: '1.25rem',
    },
    backButton: {
      cursor: 'pointer',
    },
    actions: {
      padding: theme.spacing(0, 4),
    },
    buttonContainer: {
      padding: theme.spacing(0, 4),
    },
    button: {
      borderRadius: '12px',
      height: 56,
      fontSize: '1rem',
      fontFamily: 'NeurialGrotesk',
      fontWeight: 700,
    },
  }),
  { name: 'InClinicChooseClinicPage' }
)

type InClinicChooseClinicPageProps = {
  onBackClick(): void
}

const InClinicChooseClinicPage: React.FC<InClinicChooseClinicPageProps> = ({
  onBackClick,
}) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const history = useHistory()

  const [pageView, updatePageView] = useState<string>(PageViews.search)

  const selectedClinicInfo = useSelector(getAppointmentClinic)
  const appointmentTime = useSelector(getAppointmentTime)

  const { clinics, isClinicsLoading } = useClinicsById(
    appointmentTime.clinicsId!
  )

  const handleMapMarkerClick = useCallback((childProps: Clinic) => {
    dispatch(
      appointmentActions.updateClinic({
        clinic: {
          address: childProps.address,
          examRoom: childProps.examRoom,
          id: childProps.id,
          name: childProps.name,
          phoneNumber: childProps.phoneNumber,
          url: childProps.url,
        } as Clinic,
      })
    )
  }, [])

  const handleClinicSelect = useCallback(
    (clinic) => {
      dispatch(appointmentActions.updateClinic({ clinic }))
      updatePageView(PageViews.mapView)
    },
    [dispatch]
  )

  const handleOpenClinicClick = useCallback(() => {
    window.open(selectedClinicInfo?.url || '', '_blank')
  }, [selectedClinicInfo?.url])

  return (
    <>
      <Grid
        item
        container
        alignItems="center"
        className={classes.timeSlotContainer}
      >
        <Box className={classes.backButton} onClick={() => onBackClick()}>
          <img src={getAbsoluteUrl(BackButton)} alt="" />
        </Box>

        <Box display="flex" flexDirection="column">
          <Typography
            align="left"
            className={clsx(
              classes.text,
              classes.appointmentInfo,
              classes.appointmentTime
            )}
          >
            {moment(appointmentTime.start).format('hh:mma')}
          </Typography>
          <Typography
            align="left"
            className={clsx(
              classes.text,
              classes.appointmentInfo,
              classes.appointmentDate
            )}
          >
            {moment(appointmentTime.start).format('dddd, MMM. DD, YYYY')}
          </Typography>
        </Box>
      </Grid>
      <Grid item xs container direction="column">
        <Grid item container>
          <HomePageTabs pageView={pageView} updatePageView={updatePageView} />
        </Grid>
        {pageView === PageViews.mapView && (
          <Grid
            item
            xs
            container
            justifyContent="space-between"
            direction="column"
            className={classes.mapView}
          >
            <Grid item>
              <Grid className={classes.mapContainer}>
                {isClinicsLoading && (
                  <OverlayItem>
                    <CircularProgress />
                  </OverlayItem>
                )}
                <GoogleMap
                  markers={clinics}
                  center={{
                    lat: 37.336,
                    lng: -121.902,
                  }}
                  zoom={10}
                  selectedMarkerId={selectedClinicInfo?.id}
                  onMarkerClick={handleMapMarkerClick}
                />
              </Grid>
              <Grid container justifyContent="center">
                <SelectedClinicInfoButton
                  selectedClinicInfo={selectedClinicInfo}
                  onClinicInfoClick={handleOpenClinicClick}
                />
              </Grid>
            </Grid>

            <Grid item className={classes.buttonContainer}>
              <Button
                fullWidth
                variant="contained"
                color="primary"
                className={classes.button}
                onClick={() => history.push(RoutePath.appointmentReasons)}
                disabled={!selectedClinicInfo?.id}
              >
                Choose Clinic
              </Button>
            </Grid>
          </Grid>
        )}
        {pageView === PageViews.search && (
          <ClinicsListContainer
            clinics={clinics}
            isLoading={isClinicsLoading}
            onClinicSelect={handleClinicSelect}
          />
        )}
      </Grid>
    </>
  )
}

export default InClinicChooseClinicPage
