import { State } from 'src/store/rootReducer'
import { createSelector } from 'reselect'
import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { LoadingContextDefault } from '../../utils/types'

const getAppointmentsEntities = (
  state: State
): State['appointment']['entities'] => state.appointment.entities

const getAppointmentsUI = (state: State): State['appointment']['ui'] =>
  state.appointment.ui

export const getAppointmentClinic = createSelector(
  getAppointmentsEntities,
  (entity) => entity.appointment.clinic
)

export const getAppointmentTime = createSelector(
  getAppointmentsEntities,
  (entity) => entity.appointment.dateTime
)

export const getIsWithoutMobileApp = createSelector(
  getAppointmentsEntities,
  (entity) => entity.appointment.withoutMobileApp
)

export const getIsTelemedicineClinicFlag = createSelector(
  getAppointmentsEntities,
  (entity) => entity.appointment.isTelemedicineClinic
)

export const getAvailableSlots = createSelector(
  getAppointmentsEntities,
  (entity) => entity.availableSlots
)

export const getSlotsLoading = createSelector(
  getAppointmentsUI,
  (ui) => ui.slotsLoading
)

export const getClinicByZipLoading = createSelector(
  getAppointmentsUI,
  (ui) => ui.clinicByZipLoading
)

export const getAppointment = createSelector(
  getAppointmentsEntities,
  (entity) => entity.appointment
)

export const getEligibleFlag = createSelector(
  getAppointmentsEntities,
  (entity) => entity.isEligibleZip
)

export const getSourceWidgetType = createSelector(
  getAppointmentsEntities,
  (entity) => entity.sourceWidgetType
)

export const getSelectedPaymentMethod = createSelector(
  getAppointmentsEntities,
  (entity) => entity.selectedPaymentMethod
)

export const getStripePublicKey = createSelector(
  getAppointmentsEntities,
  (entity) => entity.stripePublicKey
)

export const getStripePublicKeyLoading = createSelector(
  getAppointmentsUI,
  (ui) => ui.stripePublicKeyLoading
)

export const getCreatePaymentTokenSubmitting = createSelector(
  getAppointmentsUI,
  (ui) => ui.createPaymentTokenSubmitting
)

export const getScheduleApptLoading = createSelector(
  getAppointmentsUI,
  (ui) => ui.scheduleApptLoading
)

export const getSavedPaymentId = createSelector(
  getAppointmentsEntities,
  (entity) => entity.savedPaymentId
)

export const getSavePaymentMethodSubmitting = createSelector(
  getAppointmentsUI,
  (ui) => ui.savePaymentMethodSubmitting
)

export const getScheduledAppointment = createSelector(
  getAppointmentsEntities,
  (entity) => entity.scheduledAppointment
)

export const getPayForAppointmentSubmitting = createSelector(
  getAppointmentsUI,
  (ui) => ui.payForAppointmentSubmitting
)

export const getCheckForPendingAppointmentLoading = createSelector(
  getAppointmentsUI,
  (ui) => ui.checkForPendingAppointmentLoading
)

export const getPendingAppointment = createSelector(
  getAppointmentsEntities,
  (entity) => entity.pendingAppointment
)

const getProductByIdLoading = createSelector(
  getAppointmentsUI,
  (ui) => ui.productByIdLoading
)

const getProductById = createSelector(
  getAppointmentsEntities,
  (entity) => entity.productById
)

const makeGetProductById = (productId: string) =>
  createSelector(getProductById, (byId) => byId[productId])

const makeGetProductByIdLoading = (productId: string) =>
  createSelector(
    getProductByIdLoading,
    (loadingById) => loadingById[productId] || LoadingContextDefault
  )

export const getApptOrderType = createSelector(
  getSourceWidgetType,
  (widgetType) =>
    widgetType === 'clinic-appointment' || widgetType === 'in-clinic-home'
      ? 'IN_CLINIC_WEBVIEW'
      : 'APPOINTMENT'
)

export const getAppointmentProductId = createSelector(
  [getSourceWidgetType],
  (widgetType) =>
    widgetType === 'clinic-appointment' || widgetType === 'in-clinic-home'
      ? 'inClinicAppt'
      : 'videoConsultation'
)

export const useProductById = (productId: string) => {
  const loading = useMemo(() => makeGetProductByIdLoading(productId), [
    productId,
  ])
  const product = useMemo(() => makeGetProductById(productId), [productId])

  return {
    loading: useSelector(loading),
    product: useSelector(product),
  }
}

export const getPaymentMethods = createSelector(
  getAppointmentsEntities,
  (entity) => entity.paymentMethods
)

export const getFetchingPaymentMethods = createSelector(
  getAppointmentsUI,
  (ui) => ui.fetchingPaymentMethods
)

export const getCopayStatus = createSelector(
  getAppointmentsEntities,
  (entity) => entity.copayStatus
)

export const getFetchingCopayPossibility = createSelector(
  getAppointmentsUI,
  (ui) => ui.fetchingCopayStatus
)

export const getCopayInfoSelector = createSelector(
  getAppointmentsEntities,
  (entity) => entity.copayInfo
)

export const getFetchingCopayAmount = createSelector(
  getAppointmentsUI,
  (ui) => ui.fetchingCopayAmount
)

export const getReasonsForConsultation = createSelector(
  getAppointmentsEntities,
  (entity) => entity.reasonsForConsultation
)

export const getFetchingReasonsForConsultation = createSelector(
  getAppointmentsUI,
  (ui) => ui.fetchingReasonsForConsultation
)
