import React, { useCallback, useEffect } from 'react'
import { Grid, Theme, Typography } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { useHistory, useLocation } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import clsx from 'clsx'

import { RoutePath } from '../../routes'
import FooterButtons from '../../components/FooterButtons'
import { useClinicPreview } from '../../hooks/useClinicPreview'
import AppointmentTimeSelection from '../../containers/appointment/AppointmentTimeSelection'
import { getAppointmentTime } from '../../store/appointment/selectors'
import appointmentActions from '../../store/appointment/actions'
import PhoneNumberButton from '../../components/common/PhoneNumberButton'
import { config } from '../../utils/config'
import gtag from '../../utils/gtag'
import { TelemedicineWidgetTypes } from '../../utils/appointment/general'
import { getFormattedAddress } from '../../utils/general'
import { InClinicSlot } from '../../store/appointment/models'

const useStyles = makeStyles(
  (theme: Theme) => ({
    root: {
      padding: theme.spacing(6, 0, 4),
      flex: 1,
    },
    headerContainer: {
      padding: theme.spacing(0, 4),
    },
    info: {
      fontFamily: 'Arial',
      color: theme.palette.primary.dark,
      lineHeight: 1.4,
    },
    title: {
      fontWeight: 'bold',
      fontSize: '2.25rem',
      letterSpacing: '-1px',
    },
    clinicInfo: {
      fontFamily: 'NeurialGrotesk',
      fontSize: '1rem',
      lineHeight: 1.3,
    },
    phoneButtonContainer: {
      padding: theme.spacing(3, 3, 4, 3),
    },
    appointmentTimeSection: {},
    subTitle: {
      fontFamily: 'NeurialGrotesk',
      fontWeight: 700,
      fontSize: '1.375rem',
      lineHeight: 1.3,
      color: theme.palette.primary.dark,
      padding: theme.spacing(0, 4),
    },
    content: {
      flex: 1,
      overflow: 'auto',
      marginBottom: theme.spacing(3),
    },
    actions: {
      padding: theme.spacing(0, 4),
    },
  }),
  { name: 'SelectedClinicHomePage' }
)

interface SelectedClinicHomePageProps {
  selectedClinicId: number
  examRoom: number
  initialPage?: string
  widgetType: string
  eventName: string
  virtualVisit?: boolean
  isSymptomatic?: boolean
}

interface LocationState {
  symptomsCheckerFlow?: boolean
}

const SelectedClinicHomePage: React.FC<SelectedClinicHomePageProps> = ({
  selectedClinicId,
  examRoom,
  initialPage,
  widgetType,
  eventName,
  isSymptomatic = false,
}) => {
  const specificClinic = TelemedicineWidgetTypes.includes(widgetType)

  const classes = useStyles()
  const history = useHistory()
  const dispatch = useDispatch()
  const { state } = useLocation<LocationState>()

  useEffect(() => {
    dispatch(appointmentActions.markApptAsSymptomatic(isSymptomatic))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSymptomatic])

  const { clinicInfo, clinicInfoIsLoading } = useClinicPreview(
    selectedClinicId,
    widgetType,
    specificClinic
  )

  const appointmentTime = useSelector(getAppointmentTime)

  const handleAppTimeSelect = useCallback(
    (slot: InClinicSlot) => {
      dispatch(appointmentActions.updateAppointmentTime({ dateTime: slot }))
    },
    [dispatch]
  )

  const handleOpenUrgentCareHomePage = useCallback(() => {
    window.open(`${config.server.aucWpHost}/in-clinic-care`)
  }, [])

  const handleOpenReasons = useCallback(() => {
    history.push({
      pathname: RoutePath.appointmentReasons,
      state: {
        initialPage,
        symptomsCheckerFlow: state?.symptomsCheckerFlow,
      },
    })

    if (state?.symptomsCheckerFlow) {
      gtag('event', 'sc_openReasonsPage')
    }
  }, [history, initialPage, state])

  useEffect(() => {
    dispatch(appointmentActions.updateClinic({ clinic: clinicInfo }))
  }, [clinicInfo?.name])

  useEffect(() => {
    gtag('event', eventName)
  }, [])

  return (
    <Grid
      container
      direction="column"
      justifyContent="space-between"
      className={classes.root}
    >
      <Grid container direction="column" className={classes.content}>
        <Grid className={classes.headerContainer}>
          <Typography className={clsx(classes.title, classes.info)}>
            Book Appointment
          </Typography>
          {clinicInfoIsLoading ? (
            <Typography className={clsx(classes.info, classes.clinicInfo)}>
              Loading...
            </Typography>
          ) : (
            <>
              <Typography className={clsx(classes.info, classes.clinicInfo)}>
                {clinicInfo?.name}
              </Typography>
              {clinicInfo?.address && (
                <>
                  {getFormattedAddress(clinicInfo.address).map(
                    (addressLine) => (
                      <Typography
                        key={addressLine}
                        className={clsx(classes.info, classes.clinicInfo)}
                      >
                        {addressLine}
                      </Typography>
                    )
                  )}
                </>
              )}
            </>
          )}
        </Grid>
        <Grid className={classes.phoneButtonContainer}>
          <PhoneNumberButton
            isLoading={clinicInfoIsLoading}
            phoneNumber={clinicInfo?.phoneNumber}
          />
        </Grid>
        <Typography className={classes.subTitle}>
          Select Appointment Time
        </Typography>
        <AppointmentTimeSelection
          virtualVisit={specificClinic}
          clinicId={selectedClinicId}
          examRoom={examRoom}
          selectedTime={appointmentTime}
          onTimeSelect={handleAppTimeSelect}
        />
      </Grid>
      <Grid item>
        <FooterButtons
          nextOnly={Boolean(specificClinic)}
          classes={{ root: classes.actions }}
          nextButtonLabel="Select"
          backButtonLabel="Choose Another Clinic"
          onNextButtonClick={handleOpenReasons}
          onBackButtonClick={handleOpenUrgentCareHomePage}
          disableNext={!appointmentTime}
        />
      </Grid>
    </Grid>
  )
}

export default SelectedClinicHomePage
