import moment, { Moment } from 'moment'
import { UsersProfile } from '../auth/models'

export const getInsuranceData = (
  insuranceValues: {
    insuranceName: string
    insuranceId: string
    firstName: string
    lastName: string
    dob: Moment
  },
  generalInfo: UsersProfile,
  images: {
    front: File | null
    back: File | null
  }
) => {
  const formData = new FormData()
  if (images.front) {
    formData.append('backSide', images.front, images.front.name)
  }

  if (images.back) {
    formData.append('frontSide', images.back, images.back.name)
  }

  const details = {
    firstName: generalInfo.firstName,
    lastName: generalInfo.lastName,
    gender: generalInfo.gender,
    dateOfBirth: moment(
      generalInfo.dob || generalInfo.dateOfBirth,
      'YYYY-MM-DD'
    ).format('yyyy-MM-DD'),

    insuranceInfo: {
      isNotPrimaryInsured: true,
      insuranceName: insuranceValues.insuranceName,
      insuranceId: insuranceValues.insuranceId,
      primaryInsuredInfo: {
        firstName: insuranceValues.firstName,
        lastName: insuranceValues.lastName,
        dateOfBirth: insuranceValues.dob.format('yyyy-MM-DD'),
      },
    },
  }

  formData.append('form', JSON.stringify(details))

  return formData
}
