import { forwardRef, useCallback, useImperativeHandle, useMemo } from 'react'
import { Box, Grid, InputLabel, InputAdornment } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { Controller, useForm } from 'react-hook-form'
import MaskedInput from 'react-text-mask'
import Payment from 'payment'

import {
  autoCorrectedDatePipe,
  CC_EXPIRY_MASK,
  getFormMasks,
} from 'src/utils/creditCards'
import ThemedTextField from './common/ThemedTextField'
import { getAbsoluteUrl } from '../utils/general'
import mastercardIcon from '../assets/icons/creditCardIconsByType/mastercard.svg'
import amexIcon from '../assets/icons/creditCardIconsByType/amex.svg'
import visaIcon from '../assets/icons/creditCardIconsByType/visa.svg'
import discoverIcon from '../assets/icons/creditCardIconsByType/discover.svg'
import dinersClubIcon from '../assets/icons/creditCardIconsByType/dinersClub.svg'
import jcbIcon from '../assets/icons/creditCardIconsByType/jcb.svg'
import unionPayIcon from '../assets/icons/creditCardIconsByType/unionPay.png'

export type CreditCardFormValues = {
  number: string
  expiration: string
  cvv: string
}

export type CreditCardFormProps = {
  disabled?: boolean
  onSubmit(values: CreditCardFormValues): void
}

export type CreditCardFormActions = {
  submit(): void
}

const useStyles = makeStyles((theme) => ({
  inputLabel: {
    marginBottom: theme.spacing(1),
  },
  ccTypeIcon: {
    height: 26,
    marginRight: theme.spacing(1),
  },
}))

const defaultValues: CreditCardFormValues = {
  number: '',
  expiration: '',
  cvv: '',
}

// FIXME: add proper validation

const CreditCardForm = forwardRef<CreditCardFormActions, CreditCardFormProps>(
  ({ disabled = false, onSubmit }, ref) => {
    const classes = useStyles()

    const { control, watch, errors, handleSubmit } = useForm<
      CreditCardFormValues
    >({
      mode: 'onChange',
      shouldFocusError: false,
      defaultValues,
    })

    const values = watch()

    const inputMask = useMemo(() => getFormMasks(values.number), [
      values.number,
    ])

    const creditCardType = Payment.fns.cardType(values.number)

    const creditCardTypeIcon: Record<string, string> = useMemo(
      () => ({
        mastercard: mastercardIcon,
        visa: visaIcon,
        amex: amexIcon,
        discover: discoverIcon,
        dinersclub: dinersClubIcon,
        jcb: jcbIcon,
        unionpay: unionPayIcon,
      }),
      [creditCardType]
    )

    const submitForm = useCallback(() => handleSubmit(onSubmit)(), [
      handleSubmit,
      onSubmit,
    ])

    useImperativeHandle(ref, () => ({
      submit: submitForm,
    }))

    const cvvPlaceholder = inputMask.cvv.map(() => '-').join('')

    return (
      <Box>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <InputLabel className={classes.inputLabel}>Card Number</InputLabel>

            <Controller
              render={(props) => (
                <MaskedInput
                  mask={inputMask.creditCard}
                  render={(ref: React.Ref<any>, props: any) => (
                    <ThemedTextField
                      ref={ref}
                      disabled={disabled}
                      fullWidth
                      type="tel"
                      placeholder="---- ---- ---- ----"
                      endAdornment={
                        creditCardTypeIcon[creditCardType] ? (
                          <InputAdornment position="end">
                            <img
                              src={getAbsoluteUrl(
                                creditCardTypeIcon[creditCardType]
                              )}
                              alt=""
                              className={classes.ccTypeIcon}
                            />
                          </InputAdornment>
                        ) : (
                          undefined
                        )
                      }
                      error={Boolean(errors.number)}
                      {...props}
                    />
                  )}
                  {...props}
                />
              )}
              control={control}
              name="number"
              rules={{
                required: true,
              }}
            />
          </Grid>

          <Grid item xs={6}>
            <InputLabel className={classes.inputLabel}>
              Expiration Date
            </InputLabel>
            <Controller
              render={(props) => (
                <MaskedInput
                  disabled={disabled}
                  mask={CC_EXPIRY_MASK}
                  pipe={autoCorrectedDatePipe}
                  render={(ref: React.Ref<any>, props: any) => (
                    <ThemedTextField
                      ref={ref}
                      fullWidth
                      type="tel"
                      placeholder="--/--"
                      error={Boolean(errors.expiration)}
                      {...props}
                    />
                  )}
                  {...props}
                />
              )}
              control={control}
              name="expiration"
              rules={{
                required: true,
              }}
            />
          </Grid>
          <Grid item xs={6}>
            <InputLabel className={classes.inputLabel}>CVC/CVV</InputLabel>
            <Controller
              render={(props) => (
                <MaskedInput
                  disabled={disabled}
                  mask={inputMask.cvv}
                  render={(ref: React.Ref<any>, props: any) => (
                    <ThemedTextField
                      ref={ref}
                      fullWidth
                      type="tel"
                      placeholder={cvvPlaceholder}
                      error={Boolean(errors.cvv)}
                      {...props}
                    />
                  )}
                  {...props}
                />
              )}
              control={control}
              name="cvv"
              rules={{
                required: true,
              }}
            />
          </Grid>
        </Grid>
      </Box>
    )
  }
)

export default CreditCardForm
